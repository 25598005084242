import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { PropTypes } from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useBooleanState } from '@fingo/lib/hooks';
import { CREATE_FACTORING_SIMULATION_FILE } from '@fingo/lib/graphql';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { LoadingButton } from '@mui/lab';
import {
  offerDateToPaySelected,
  shoppingCartOffersVar,
} from '@fingo/lib/apollo/reactive-variables';
import { addMoneyWithCurrency } from '@fingo/lib/helpers';
import { calculateCommission } from '@fingo/lib/helpers/invoice-amounts-calculation';
import AddchartIcon from '@mui/icons-material/Addchart';
import { FactoringSimulationSummary, FactoringSimulationDataGrid } from '.';
import {
  everyInvoiceSameCompany,
  everyInvoiceSameStatus,
  buildInvoicesDataToSimulate,
} from '../../../helpers';
import { selectedInvoiceIdsVar } from '../../../graphql/reactive-variables';

const FactoringSimulationDialog = ({ selectedInvoices, columns }) => {
  const [invoicesDataToSimulate, setInvoicesDataToSimulate] = useState([]);
  const [open, toggleDialog] = useBooleanState(false);
  const companyRut = selectedInvoices[0]?.company.masterEntity.id;
  const everyInvoiceSameCompanyBoolean = useMemo(
    () => everyInvoiceSameCompany(selectedInvoices, companyRut),
    [selectedInvoices, companyRut],
  );
  const everyInvoiceSameStatusBoolean = useMemo(
    () => everyInvoiceSameStatus(selectedInvoices),
    [selectedInvoices],
  );
  const handleClose = useCallback(() => {
    offerDateToPaySelected({});
    toggleDialog();
  }, []);
  const [CreateSimulationFile, { loading }] = useMutation(
    CREATE_FACTORING_SIMULATION_FILE,
    {
      variables: {
        invoiceIds: invoicesDataToSimulate.map((inv) => inv.id),
        invoiceDays: invoicesDataToSimulate.map((inv) => inv.days),
        simulationType:
          selectedInvoices[0]?.invoiceStatus === 'PREOFFER'
            ? 'PREOFFER'
            : 'OFFER',
        commission: Math.round(
          calculateCommission(
            invoicesDataToSimulate[0]?.company.currentCommissionRules,
            invoicesDataToSimulate.reduce(
              (prev, curr) => addMoneyWithCurrency(prev, curr.amountWithIva),
              0,
            ),
          ).amount,
        ),
      },
      onCompleted: (data) => {
        window.open(data.createSimulationFile.url);
        selectedInvoiceIdsVar([]);
        handleClose();
      },
    },
  );
  useEffect(() => {
    setInvoicesDataToSimulate(buildInvoicesDataToSimulate(selectedInvoices));
    // set apollo cache variable for using dateToPays
    const newSelectedInvoices = selectedInvoices.map(({ id }) => {
      const existingOffer = shoppingCartOffersVar().find(
        ({ invoiceId }) => invoiceId === id,
      );
      return existingOffer || { invoiceId: id, dateToPay: null };
    });
    shoppingCartOffersVar(newSelectedInvoices);
  }, [selectedInvoices]);
  return useMemo(
    () => (
      <>
        <Tooltip
          title={
            (selectedInvoices.length === 0 && 'Selecciona facturas')
            || (!everyInvoiceSameStatusBoolean
              && 'Las facturas deben estan en el mismo estado de operación')
            || (!everyInvoiceSameCompanyBoolean
              && 'Las facturas deben pertenecer a la misma compañía')
            || (selectedInvoices.length !== 0 && 'Generar simulación')
          }
        >
          <div>
            <IconButton
              onClick={toggleDialog}
              disabled={
                !everyInvoiceSameCompanyBoolean
                || !everyInvoiceSameStatusBoolean
              }
              color="primary"
            >
              <AddchartIcon fontSize="small" />
            </IconButton>
          </div>
        </Tooltip>
        <FingoDialog
          maxWidth="1050px"
          fullWidth
          open={open}
          handleClose={handleClose}
          title="Generar simulación"
          dialogActionButton={(
            <Tooltip title="Debes ingresar todas las fechas de pago">
              <span>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={CreateSimulationFile}
                  loading={loading}
                  disabled={
                    !invoicesDataToSimulate.every((inv) => inv.dateToPay.isValid())
                  }
                >
                  Descargar PDF
                </LoadingButton>
              </span>
            </Tooltip>
          )}
        >
          <FactoringSimulationSummary
            invoicesDataToSimulate={invoicesDataToSimulate}
          />
          <FactoringSimulationDataGrid
            invoicesDataToSimulate={invoicesDataToSimulate}
            setInvoicesDataToSimulate={setInvoicesDataToSimulate}
            columns={columns}
          />
        </FingoDialog>
      </>
    ),
    [
      selectedInvoices,
      everyInvoiceSameStatusBoolean,
      everyInvoiceSameCompanyBoolean,
      loading,
      invoicesDataToSimulate,
      open,
    ],
  );
};

FactoringSimulationDialog.propTypes = {
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      invoiceStatus: PropTypes.string,
      company: PropTypes.shape({
        masterEntity: PropTypes.shape({
          name: PropTypes.string,
          rut: PropTypes.string,
          id: PropTypes.string,
        }),
      }),
      offer: PropTypes.shape({
        id: PropTypes.string,
        monthlyRate: PropTypes.number,
      }),
    }),
  ),
  columns: PropTypes.arrayOf(PropTypes.string),
  setSelectedInvoiceIds: PropTypes.func.isRequired,
};

FactoringSimulationDialog.defaultProps = {
  selectedInvoices: [],
  columns: [
    'folio',
    'company_MasterEntity_Name',
    'receiver_sortable',
    'amountWithIva',
    'offerMonthlyRate',
    'offerDefaultRate',
    'offerRetentionRate',
    'dateToPay',
    'priceDifference',
  ],
};

export default FactoringSimulationDialog;
