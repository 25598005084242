import React, { useState } from 'react';
import { DocumentList } from '@fingo/lib/components/lists';
import {
  useInvoicePreColumns,
  useInvoiceLucilaCustomColumns,
} from '@fingo/lib/constants';
import { HISTORIC_INVOICES, MY_OFFERS } from '@fingo/lib/graphql';
import { useQuery } from '@apollo/client';
import { REJECTED_STATUS, EXPIRED_STATUS } from '@fingo/lib/constants/invoices';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import RaiseOperativeRequestIconButton from '@fingo/lib/views/operativeRequest/RaiseOperativeRequestIconButton';

import {
  DownloadExcelFromMenu,
  ReceedAction,
  UnexpireInvoicesAction,
  MenuForActions,
} from './headers-actions';

const HistoricManager = () => {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { data: selectedInvoiceData } = useQuery(MY_OFFERS, {
    variables: { id_In: selectedInvoiceIds, first: 100 },
    skip: selectedInvoiceIds.length === 0,
  });

  const selectedInvoices = selectedInvoiceData
    ? selectedInvoiceData.invoices.flatInvoices
    : [];

  return (
    <DocumentList
      trackerId="HISTORIC_INVOICES"
      type="historic-invoices-list"
      headerTitle="Histórico de facturas/Rechazos/Expiraciones"
      queryDocument={HISTORIC_INVOICES}
      countryFilter
      initialOrderBy="dateToPay"
      includeHeaders={[
        'folio-invoice-profile',
        'dateIssued',
        'company_profile',
        'receiver_sortable',
        'amountWithIva',
        'surplusDebt',
        'offerMonthlyRate',
        'offerDefaultRate',
        'offerRetentionRate',
        'dateToPay',
        'paymentDate',
        'simpleStatus',
      ]}
      mobileHeaders={[
        'folio-invoice-profile',
        'company_MasterEntity_Name',
        'receiver_Name',
      ]}
      showFilters
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showCompanyIssuerFilter: true,
        defaultFilterDays: 30,
      }}
      checkboxSelection
      selectionModel={selectedInvoiceIds}
      onSelectionModelChange={(ids) => setSelectedInvoiceIds(ids)}
      isRowSelectable={({ row }) => REJECTED_STATUS.concat(EXPIRED_STATUS).includes(row.status)}
      allCompaniesOptions
      getRowId={(row) => row.id}
      customVariables={{
        receiver_Rut: null,
        receiverId: null,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
        status: [
          'Finished',
          'SurplusWithdrawn',
          'Rejected',
          'RejectedRatification',
          'RejectedTransfer',
          'Expired',
        ],
        filterByRequestingPlatformOrAssigned: 'FINGO',
      }}
      preColumns={useInvoicePreColumns(useInvoiceLucilaCustomColumns(false))}
      flexEndButtons={(rowCount, commonVars) => (
        <>
          <RaiseOperativeRequestIconButton
            selectedInvoices={selectedInvoiceIds}
            setSelectedInvoiceIds={setSelectedInvoiceIds}
          />
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>

          <MenuForActions
            actions={[
              ReceedAction,
              UnexpireInvoicesAction,
              DownloadExcelFromMenu,
            ]}
            selectedInvoices={selectedInvoices}
            setSelectedInvoiceIds={setSelectedInvoiceIds}
            rowCount={rowCount}
            commonVars={commonVars}
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </>
      )}
    />
  );
};

export default HistoricManager;
